import request from '@/utils/request'

// 查询操作历史
export function findOperationHistories(params) {
  return request({
    url: `/operation_histories`,
    method: 'get',
    params: params
  })
}


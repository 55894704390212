<template>
  <div :style="{ height: this.height }" class="scroll-box">
    <a-spin :spinning="loading">
      <a-timeline v-if="data.length > 0">
        <a-timeline-item v-for="history in data" :key="history.id">
          <p>
            <a-space>
              <span>{{ history.created_at }}</span>
              <span>{{ history.operation_type }}</span>
              <span>({{ history.operator_name }})</span>
            </a-space>
          </p>
          <p>{{ history.operation_content }}</p>
        </a-timeline-item>
      </a-timeline>
      <a-empty v-else />
    </a-spin>
  </div>
</template>

<script>
import { findOperationHistories } from '@/api/operation_history'

export default {
  name: 'OperationHistoryList',
  props: {
    referenceType: {
      type: String,
      required: true
    },
    referenceId: {
      type: Number,
      required: true
    },
    height: {
      type: String,
      default: '400px'
    }
  },
  data() {
    return {
      loading: true,
      data: []
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      findOperationHistories({ reference_type: this.referenceType, reference_id: this.referenceId }).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.scroll-box {
  overflow-y: auto;
  padding-top: 5px;
  padding-right: 10px;
}
</style>
